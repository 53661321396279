import React, { Component, Fragment } from 'react';
import MeetingEventInfo from './Detail';
import Shoprelated from '../../layouts/Shoprelated';

function Content(props) {
  const { selectedOption, setTotalLoads } = props;
  return (
    <Fragment>
      <MeetingEventInfo selectedOption={selectedOption} setTotalLoads={setTotalLoads} />
      {/* <Shoprelated/> */}
    </Fragment>
  );
}

export default Content;
