import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import $ from 'jquery';
import 'magnific-popup';

import aboutimg from '../../../assets/img/text-block/05.jpg';
import videobg from '../../../assets/img/text-block/04.jpg';

import FiveStartServiceImg from '../../../assets/img/about/five-start-service.png';
import WorldClassChefsImg from '../../../assets/img/about/word-class-chefs.png';
import TopCuisineImg from '../../../assets/img/about/top-cuisine.png';
import { useOnScreen } from 'utils';

const featuresposts = [
  { icon: 'fas fa-hotel', title: '5 Star Service', step: 'stepOne' },
  { icon: 'fas fa-utensils', title: 'World Class Chefs', step: 'stepTwo' },
  { icon: 'fas fa-glass-martini', title: 'Top Cuisine', step: 'stepThree' },
];

const allContent = {
  stepOne: {
    key: '1',
    content: 'Personalization of top-class services is our pride',
    title: 'Get the best Offers and rates at our destination',
    span: 'SPECIAL OFFER',
    img: FiveStartServiceImg,
  },
  stepTwo: {
    key: '2',
    content: 'Best of resources and skills to satiate your top-grade requirements.',
    title: 'Get the best Offers and rates at our destination',
    span: 'TOP CHEFS',
    img: WorldClassChefsImg,
  },
  stepThree: {
    key: '3',
    content: 'Impressionable palette worthy and diverse cuisine for outstanding events.',
    title: 'Get the best Offers and rates at our destination',
    span: 'SPECIAL CUISINE',
    img: TopCuisineImg,
  },
};

/**
 * MAIN COMP
 * About Page Comp
 */
function About() {
  const videoRef = useRef(null);
  const isVisibleVideo = useOnScreen(videoRef);

  const [isVideoPlaying, setIsVideoPlaying] = useState(false);

  const [selectedContent, setSelectedContent] = useState(allContent.stepOne);

  useEffect(() => {
    if (isVisibleVideo && isVisibleVideo === true && !isVideoPlaying) {
      setIsVideoPlaying(true);
      videoRef.current.play();
    }
  }, [isVisibleVideo]);

  return (
    <section className="about-section pt-115 pb-115">
      <div className="container">
        {/* <div className="video-wrap video-wrap-two video-about mb-60" style={{ backgroundImage: "url(" + videobg + ")" }}>
                        <Link to="http://www.youtube.com/embed/watch?v=EEJFMdfraVY" className="popup-video"><i className="fas fa-play" /></Link>
                    </div> */}
        <div className="section-title about-title text-center">
          {/* <span className="title-tag">
            since <span>1994</span>
          </span> */}
          <h2>
            31 years as connoisseurs of culinary expertise. Pièce de résistance of first class events. Customized star
            service providers
          </h2>
        </div>
        <ul className="about-features">
          {featuresposts.map((item, i) => (
            <li key={i} style={{ marginLeft: 10 }}>
              <div
                className="pg"
                to="#"
                onClick={() => {
                  if (item.step) {
                    setSelectedContent(allContent[item.step]);
                  }
                }}
              >
                <i className={item.icon} />
                <i className={'hover-icon ' + item.icon + ''} />
                <span className="title">{item.title}</span>
              </div>
            </li>
          ))}
        </ul>
        <div className="about-text-box">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="about-img w3-animate-opacity">
                <img src={selectedContent.img || aboutimg} alt="images" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-text">
                <span>{selectedContent.span || ''}</span>
                <h3>{selectedContent.content || ''}</h3>
                {/* <p className="mb-4">{selectedContent.content}</p> */}
              </div>
            </div>
          </div>
        </div>
        <div className="about-text-box">
          <video
            muted
            autoPlay={true}
            width="100%"
            src="https://firebasestorage.googleapis.com/v0/b/riyads-catering-client.appspot.com/o/Intro.mp4?alt=media"
            type="video/mp4"
            ref={videoRef}
            loop
          />
        </div>
      </div>
    </section>
  );
}

export default About;
