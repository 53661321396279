import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';

import breadcrumbimg from '../../assets/img/bg/04-1920x293.png';

function Breadcrumbs(props) {
  const { title = '', pagename = '', image, setTotalLoads } = props;

  useEffect(() => {
    const imageLoader = new Image();
    imageLoader.src = image || breadcrumbimg;
    imageLoader.onload = () => {
      if (setTotalLoads) {
        setTotalLoads(prevState => prevState + 1);
      }
    };
  }, []);

  return (
    <section className="breadcrumb-area" style={{ backgroundImage: 'url(' + image || breadcrumbimg + ')' }}>
      <div className="container">
        <div className="breadcrumb-text">
          <span>{title}</span>
          <h2 className="page-title" >
            {pagename}
          </h2>
          <ul className="breadcrumb-nav">
            <li>
              <Link to="/">Home</Link>
            </li>
            <li className="active">{pagename}</li>
          </ul>
        </div>
      </div>
    </section>
  );
}

export default Breadcrumbs;
