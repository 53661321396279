import React, { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import Backtotop from './Backtotop';
import { useTranslation } from 'react-i18next';

import logo from '../../assets/img/logo-white-riyad.png';

function FooterTwo() {
  const { t } = useTranslation();

  return (
    <Fragment>
      <Backtotop />
      <footer className="footer-two">
        <div className="footer-widget-area pt-100 pb-50">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 col-sm-6 order-1">
                {/* Site Info Widget */}
                <div className="widget site-info-widget mb-50">
                  <div className="footer-logo mb-50">
                    <img src={logo} alt="Logo" />
                  </div>
                  <p className="nameTag" style={{ fontStyle: 'italic' }}>
                    {t('global:nameTag')}
                  </p>
                  <div className="social-links mt-40">
                    <Link to="#">
                      <i className="fab fa-facebook-f" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-twitter" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-behance" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-linkedin" />
                    </Link>
                    <Link to="#">
                      <i className="fab fa-youtube" />
                    </Link>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 order-3 order-lg-2">
                {/* Nav Widget */}
                <div className="widget nav-widget mb-50">
                  <div>
                    <h4 className="widget-title">Frequent Picks.</h4>
                    <ul>
                      <li>
                        <Link to="/meeting-events?id=meeting-conference">Meeting / Conference</Link>
                      </li>
                      <li>
                        <Link to="/meeting-events?id=banquet-standing-lunch">Banquet / Standing Lunch</Link>
                      </li>
                      <li>
                        <Link to="/gallery">{t('global:gallery')}</Link>
                      </li>
                      <li>
                        <Link to="/about">{t('global:about')}</Link>
                      </li>
                      <li>
                        <Link to="/contact">{t('global:contact')}</Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-sm-6 order-2 order-lg-3">
                {/* Contact Widget */}
                <div className="widget contact-widget mb-50">
                  <h4 className="widget-title">Contact Us.</h4>
                  <div className="contact-lists">
                    <div className="contact-box">
                      <div className="icon">
                        <i className="flaticon-phone" />
                      </div>
                      <div className="desc">
                        <h6 className="title">Phone Number</h6>+ 41 31 350 32 30
                      </div>
                    </div>
                    <div className="contact-box">
                      <div className="icon">
                        <i className="flaticon-message" />
                      </div>
                      <div className="desc">
                        <h6 className="title">Email Address</h6>
                        <a href={'mailto:emmanuel.jud@upu.int'}>emmanuel.jud@upu.int</a>
                      </div>
                    </div>
                    <div className="contact-box">
                      <div className="icon">
                        <i className="flaticon-location-pin" />
                      </div>
                      <div className="desc">
                        <h6 className="title">Office Address</h6>
                        <span>Universal Postal Union (UPU)</span>
                        <span>Weltpoststrasse 4, 3015 Bern</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area pt-30 pb-30">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-8 col-md-5 order-2 order-md-1">
                <p className="roboto">
                  Visit{' '}
                  <a href="https://www.fotoelvey.ch" target="_blank">
                    Foto Elvey
                  </a>{' '}
                  To Find Your're best image
                </p>
              </div>
              <div className="col-lg-4 col-md-7 order-1 order-md-2">
                {/* <p className="copyright-text copyright-two">
                  Copyright By @<Link to="#"> Riyads Catering</Link> - 2022
                </p> */}
                {/* <div className="footer-menu text-center text-md-right">
                  <ul>
                    <li>
                      <Link to="#">Terms of use</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Environmental Policy</Link>
                    </li>
                  </ul>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
}
export default FooterTwo;
