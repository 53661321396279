import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import './index.css';

import img1 from '../../assets/img/instagram/new-01.jpg';
import img2 from '../../assets/img/instagram/new-02.jpg';
import img3 from '../../assets/img/instagram/03.jpg';
import img4 from '../../assets/img/instagram/04.jpg';
import img5 from '../../assets/img/instagram/05.jpg';
import img6 from '../../assets/img/instagram/06.jpg';

const instapost = [{ photo: img1 }, { photo: img2 }];

class ImageSlider extends Component {
  render() {
    const settings = {
      slidesToShow: 1,
      slidesToScroll: 1,
      fade: false,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 4000,
      arrows: true,
      dots: true,
      // responsive: [{
      //     breakpoint: 992,
      //     settings: {
      //         slidesToShow: 4,
      //     },
      // },
      // {
      //     breakpoint: 768,
      //     settings: {
      //         slidesToShow: 3,
      //     },
      // },
      // {
      //     breakpoint: 576,
      //     settings: {
      //         slidesToShow: 2,
      //     },
      // },
      // ],
    };

    return (
      <div className="instagram-feed-section">
        <div className="container-fluid p-0">
          <Slider {...settings}>
            {instapost.map((item, i) => (
              <Link key={i} to={'#'} className="insta-popup">
                {/* <img src={item.photo} alt="instagram-feed"  /> */}
                <div
                  className="slider-custom"
                  style={{
                    backgroundImage: 'url(' + item.photo + ')',
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                  }}
                >
                  {/* <h1
                                        style={{
                                            color: '#FFF',
                                            textAlign: 'center',
                                            fontWeight: 'bold',
                                        }}
                                        className="default-font"
                                    >
                                        Brasserie VUE
                                    </h1>
                                    <div
                                        style={{
                                            justifyContent: 'center',
                                            alignContent: 'center',
                                            alignItems: 'center',
                                            display: 'flex',
                                        }}
                                    >
                                        <p
                                            style={{
                                                textAlign: 'center',
                                                width: '50%',
                                                color: '#FFF',
                                                fontSize: 20,
                                            }}
                                            className="default-font"
                                        >
                                            We recommend you a cuisine with the product and the handcraft as
                                            the centre. “Neo-artisanal” is classical but at the same time
                                            modern, with dishes to share and to enjoy together.
                                        </p>
                                    </div> */}
                </div>
              </Link>
            ))}
          </Slider>
        </div>
        {/* <img src={img1} alt="instagram-feed" width={900} height={500}/> */}
      </div>
    );
  }
}

export default ImageSlider;
