import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';
import Cursor from '../../layouts/Cursor';

import img2 from '../../../assets/img/banner/05-1920x700.JPG';
import img3 from '../../../assets/img/banner/05-1920x700-3.png';
import img1 from '../../../assets/img/banner/07-1600x800.jpg';

const bannerposts = [
  {
    img: img1,
    tag: 'Tasteful executive socials',
    title: 'Meticulous, Exclusive & Cosmopolitan',
    btn1: 'explore more',
    btn2: 'Contact us',
  },
  {
    img: img2,
    tag: 'Memorable Gatherings',
    title: 'Elite Delectable Cuisines',
    btn1: 'explore more',
    btn2: 'Contact us',
  },
];

const settings = {
  infinite: true,
  autoplay: true,
  autoplaySpeed: 5000,
  dots: false,
  fade: true,
  arrows: false,
};

function Banner() {
  return (
    <Slider className="banner-area banner-style-three" id="bannerSlider" {...settings}>
      {bannerposts.map((item, i) => (
        <div key={i} className="single-banner d-flex align-items-center justify-content-center">
          <Cursor />
          <div className="container">
            <div className="row">
              <div className="col-lg-9">
                <div className="banner-content">
                  <span className="promo-tag ml-2" data-animation="fadeInDown" data-delay=".6s">
                    {item.tag}
                  </span>
                  <h5 className="title" data-animation="fadeInLeft" data-delay=".9s" style={{ minWidth: 400 }}>
                    {item.title}
                  </h5>
                  <ul>
                    <li data-animation="fadeInUp" data-delay="1.1s">
                      {' '}
                      <Link className="main-btn-custom-home" to="/about">
                        {/* {item.btn1} */}
                      </Link>
                    </li>
                    <li data-animation="fadeInUp" data-delay="1.3s">
                      {' '}
                      <Link className="main-btn-custom-home" to="/about">
                        {/* {item.btn2} */}
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          {/* banner bg */}
          <div className="banner-bg" style={{ backgroundImage: 'url(' + item.img + ')' }} />
          <div className="banner-overly" />
        </div>
      ))}
    </Slider>
  );
}

export default Banner;
