export const allOptions = [
  {
    id: 'meeting-conference',
    name: 'Meeting & Conference',
    type: 'meeting',
  },
  {
    id: 'conference',
    name: 'Conference',
    type: 'meeting',
  },
  {
    id: 'seminar',
    name: 'Seminar',
    type: 'meeting',
  },
  {
    id: 'banquet-standing-lunch',
    name: 'Banquet & Standing Lunch',
    type: 'events',
  },
  {
    id: 'corporate-events',
    name: 'Corporate Events',
    type: 'events',
  },
];
