import React, { Component } from 'react';
import $ from 'jquery';

import aboutimg1 from '../../../assets/img/bg/about-1-500x500.jpg';
import aboutimg2 from '../../../assets/img/bg/about-4-500x250.jpg';

class About extends Component {
    componentDidMount() {
        function parallaxscroll() {
            $(document).ready(function () {
                //parallax scroll
                $(window).on("scroll", function () {
                    var parallaxElement = $(".parallax_scroll_up"),
                        parallaxQuantity = parallaxElement.length;
                    window.requestAnimationFrame(function () {
                        for (var i = 0; i < parallaxQuantity; i++) {
                            var currentElement = parallaxElement.eq(i),
                                windowTop = $(window).scrollTop(),
                                elementTop = currentElement.offset().top,
                                elementHeight = currentElement.height(),
                                viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
                                scrolled = windowTop - elementTop + viewPortHeight;
                            currentElement.css({
                                transform: "translate3d(0," + scrolled * -0.25 + "px, 0)"
                            });
                        }
                    });
                    var parallaxElement2 = $(".parallax_scroll_down"),
                        parallaxQuantity2 = parallaxElement2.length;
                    window.requestAnimationFrame(function () {
                        for (var i = 0; i < parallaxQuantity2; i++) {
                            var currentElement2 = parallaxElement2.eq(i),
                                windowTop = $(window).scrollTop(),
                                elementTop = currentElement2.offset().top,
                                elementHeight = currentElement2.height(),
                                viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
                                scrolled = windowTop - elementTop + viewPortHeight;
                            currentElement2.css({
                                transform: "translate3d(0," + scrolled * 0.05 + "px, 0)"
                            });
                        }
                    });
                });
            });
        }
        parallaxscroll();
    }
    render() {
        return (
            <section className="about-section style-2 pt-115 pb-10 home-about-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft parallax_scroll_down" data-wow-delay=".3s">
                            <img src={aboutimg1} className="img-fluid" alt="img" />
                            <p className="mt-20">Elegantly designed to best suit refined palettes, our conference rooms boast a lavish ambience accommodating to maximum 300 seats. Our exceptional service can methodize social gatherings starting at 10 heads to aforementioned.</p>
                        </div>
                        <div className="col-lg-6 wow fadeInRight parallax_scroll_up" data-wow-delay=".3s">
                            <div className="abour-text mt-100">
                                <div className="section-title mb-30">
                                    <span className="title-tag">WHAT WE OFFER</span>
                                    <h3>Conferences & Suites</h3>
                                </div>
                                <p className="mb-20">Our pièce de résistance is our finest ambrosial culinary coupled with scrupulous organization cooperative to any sudden alterations warranting a smooth continuance of events.</p>
                                <img src={aboutimg2} alt="img" />
                                <p className="mt-20">We at Riyad’s Catering pride in pliable catering services to match your every need, be it the occasion, cuisine, agenda or number of guests. Lasting impressions are our service policy. Write to us and our Event Manager will assemble you a hospitable top-notch stellar event.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default About;