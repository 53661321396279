import React, { Component, Fragment } from 'react';
import About from './About';
import AboutCustom from './AboutCustom';
import Banner from './Banner';
import Counter from './Counter';
import Locations from './Locations';
import Habout from '../../layouts/Habout';
import Ourproducts from '../../layouts/Ourproducts';
import Cta from './Cta';
import CtaNew from './Cta-New';
import Blogpost from '../../layouts/Blogpost';
import Newsletter from '../homethree/Newsletter';

import ImageSlider from 'components/layouts/ImageSlider';
import ImageSliderAdvanced from 'components/layouts/ImageSliderAdvanced';

class Content extends Component {
  render() {
    return (
      <Fragment>
        <Banner />
        <About />
        <AboutCustom />
        {/* <Locations /> */}
        <Habout />
        {/* <Ourproducts /> */}
        {/* <Counter /> */}
        {/* <Cta /> */}

        <CtaNew />
        {/* <Blogpost /> */}
        {/* <Newsletter /> */}
        <br />
        <br />
        <br />
        {/* <ImageSliderAdvanced /> */}
        <br />
      </Fragment>
    );
  }
}

export default Content;
