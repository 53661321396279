import React, { useState, useEffect } from 'react';
import ReactMapboxGl, { Layer, Feature } from 'react-mapbox-gl';
import GoogleMapReact from 'google-map-react';
import { BASE_URL } from 'constants/api';
import { Alert, Spinner, Toast } from 'react-bootstrap';

const coordinate = [46.93926588083635, 7.473333561865588];

const Marker = ({ text }) => (
  <div>
    <i className="fas fa-map-marker fa-2x" style={{ color: 'blue' }}></i>
  </div>
);

function Content() {
  const Map = ReactMapboxGl({
    accessToken: 'pk.eyJ1IjoiYXJzaGFkYXphYWQiLCJhIjoiY2tzcWZsNDExMGNhNTJxbjFuNTBycWs5eiJ9.UBhKS7lRFbQTSQvLkkmreQ',
  });

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: null, variant: 'success' });

  const handleInput = e => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    // setLoading(true);

    const { email, fullName, subject, message } = formData;

    if (
      !email ||
      !fullName ||
      !subject ||
      !message ||
      email === '' ||
      fullName === '' ||
      subject === '' ||
      message === ''
    ) {
      setAlert({ ...alert, message: 'Fill all fields', variant: 'danger' });
      setLoading(false);
      return;
    }

    setAlert({ ...alert, message: null });

    try {
      const response = await fetch(`${BASE_URL}test/rc-email-inquiry`, {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
      if (response && response.status === 200) {
        setAlert({ ...alert, message: 'Successfully submitted request', variant: 'success' });
        setFormData({
          email: '',
          fullName: '',
          subject: '',
          message: '',
          phoneNumber: '',
        });
        try {
          e.target.reset();
        } catch (e) {}

        setTimeout(() => {
          setAlert({ ...alert, message: null });
        }, 1500);
      }
    } catch (e) {
      console.log(e);
      setAlert({ ...alert, message: 'Unable to send email', variant: 'danger' });
    }

    setLoading(false);
  };

  return (
    <section className="contact-part pt-115 pb-115">
      <div className="container">
        {/* Contact Info */}

        <div className="contact-info">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-sm-6 col-10">
              <div className="info-box">
                <div className="icon">
                  <i className="flaticon-home" />
                </div>
                <div className="desc">
                  <h4>Address</h4>
                  <p style={{ fontSize: 13 }}>Universal Postal Union (UPU)</p>
                  <p> Weltpoststrasse 4, 3015 Bern</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-10">
              <div className="info-box">
                <div className="icon">
                  <i className="flaticon-phone" />
                </div>
                <div className="desc" style={{ paddingBottom: 20 }}>
                  <h4>Phone Number</h4>
                  {/* <p style={{ marginBottom: 20 }}>
                      + 41 31 350 32 30
                      <br />
                    </p> */}
                  <a href="tel:+ 41 31 350 32 30" style={{ color: '#a3a3a3' }}>
                    + 41 31 350 32 30
                    <br />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-lg-4 col-sm-6 col-10">
              <div className="info-box">
                <div className="icon">
                  <i className="flaticon-message" />
                </div>
                <div className="desc" style={{ paddingBottom: 20 }}>
                  <h4>Email Address</h4>
                  {/* <p style={{ marginBottom: 20 }}><br /> jobs.webmail@mail.com</p> */}
                  <a href={'mailto:emmanuel.jud@upu.int'} style={{ color: '#a3a3a3' }}>
                    emmanuel.jud@upu.int
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Contact Mapts */}
        {/* <Map
            style="mapbox://styles/mapbox/streets-v9"
            containerStyle={{
              height: '60vh',
              // width: '100vw',
            }}
            className="contact-maps mb-30"
            // center={coordinate}
          >
            <Layer type="symbol" id="marker" layout={{ 'icon-image': 'marker-15' }}>
              <Feature coordinates={[-77.04, 38.907]} zoom={11.5} />
            </Layer>
          </Map> */}
        <div style={{ height: '70vh', width: '100%' }}>
          <GoogleMapReact
            bootstrapURLKeys={{ key: 'AIzaSyCGQIG6NR8l05WBRqgrBO2MX7XYy-9_a1Q' }}
            defaultCenter={{ lat: 46.93926588083635, lng: 7.473333561865588 }}
            defaultZoom={15}
          >
            <Marker lat={46.93926588083635} lng={7.473333561865588} text="" />
          </GoogleMapReact>
        </div>
        <br />
        <br />
        {/* Contact Form */}
        <div className="contact-form">
          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-6">
                <div className="input-group mb-30">
                  <span className="icon">
                    <i className="far fa-user" />
                  </span>
                  <input
                    type="text"
                    placeholder="Your full name"
                    id="fullName"
                    name="fullName"
                    onChange={handleInput}
                    value={formData.fullName}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-30">
                  <span className="icon">
                    <i className="far fa-envelope" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter email address"
                    id="email"
                    name="email"
                    onChange={handleInput}
                    value={formData.email}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-30">
                  <span className="icon">
                    <i className="far fa-phone" />
                  </span>
                  <input
                    type="text"
                    placeholder="Add phone number"
                    id="phoneNumber"
                    name="phoneNumber"
                    onChange={handleInput}
                    value={formData.phoneNumber}
                  />
                </div>
              </div>
              <div className="col-md-6">
                <div className="input-group mb-30">
                  <span className="icon">
                    <i className="far fa-book" />
                  </span>
                  <input
                    type="text"
                    placeholder="Subject"
                    id="subject"
                    name="subject"
                    onChange={handleInput}
                    value={formData.subject}
                  />
                </div>
              </div>
              <div className="col-12">
                <div className="input-group textarea mb-30">
                  <span className="icon">
                    <i className="far fa-pen" />
                  </span>
                  <textarea
                    placeholder="Enter messages"
                    defaultValue={''}
                    id="message"
                    name="message"
                    onChange={handleInput}
                    value={formData.message}
                  />
                </div>
              </div>
              {alert.message && (
                <Alert variant={alert.variant} className="w-100">
                  {alert.message}
                </Alert>
              )}
              <div className="col-12 text-center">
                <button type="submit" className="main-btn btn-filled" disabled={loading}>
                  {loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignContent: 'center',
                        alignItems: 'center',
                        paddingTop: 10,
                        paddingBottom: 10,
                      }}
                    >
                      <Spinner animation="border" className="mt-1" size="sm" />
                    </div>
                  ) : (
                    <>Send a request</>
                  )}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
  );
}

export default Content;
