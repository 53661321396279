import React, { useEffect, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../layouts/Header';
import Breadcrumb from '../layouts/Breadcrumbs';
import Instafeeds from '../layouts/Instafeeds';
import Footer from '../layouts/Footertwo';
import Content from '../sections/about/Content';

const ENV = process.env.REACT_APP_ENV;

/**
 * MAIN COMP
 * About Page Comp
 * @param {*} props
 * @returns
 */
function AboutPage(props) {
  useEffect(() => {
    function handleScroll() {
      if (!ENV || ENV !== 'development') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    handleScroll();
  }, []);

  return (
    <Fragment>
      {!ENV ||
        (ENV !== 'development' && (
          <MetaTags>
            <title>Riyads Catering | About Us</title>
            <meta name="description" content="#" />
          </MetaTags>
        ))}
      <Header />
      <Breadcrumb breadcrumb={{ pagename: 'About Us' }} />
      <Content />
      {/* <Instafeeds /> */}
      <Footer />
    </Fragment>
  );
}

export default AboutPage;
