import { hot } from 'react-hot-loader/root';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
// import { SnackbarProvider } from 'notistack';
import * as serviceWorker from './serviceWorker';

// Css
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/slick-carousel/slick/slick.css';
import '../node_modules/slick-carousel/slick/slick-theme.css';
import '../node_modules/animate.css/animate.css';
import '../node_modules/magnific-popup/dist/magnific-popup.css';
import './assets/css/font-awesome.min.css';
import './assets/css/flaticon.css';
import './assets/fonts/flaticon/flaticon-2.css';
import './assets/css/default.css';
import './assets/css/style.css';

import './index.css';

const MOUNT_NODE = document.getElementById('root');

ReactDOM.render(
  <BrowserRouter>
    {/* <SnackbarProvider maxSnack={3}> */}
    <App />
    {/* </SnackbarProvider> */}
  </BrowserRouter>,
  document.getElementById('root'),
);

const ConnectedApp = ({ Component }) => (
  <React.StrictMode>
    <BrowserRouter>
      {/* <SnackbarProvider maxSnack={3}> */}
      <Component />
      {/* </SnackbarProvider> */}
    </BrowserRouter>
  </React.StrictMode>
);

const render = Component => {
  ReactDOM.render(<ConnectedApp Component={Component} />, MOUNT_NODE);
};

if (module.hot) {
  // Hot reloadable translation json files and app
  // modules.hot.accept does not accept dynamic dependencies,
  // have to be constants at compile-time
  module.hot.accept(['./App.js'], () => {
    ReactDOM.unmountComponentAtNode(MOUNT_NODE);
    const App = require('./App.js').App;
    render(App);
  });
}

render(App);

export default hot(App);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
