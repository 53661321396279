import React, { useEffect, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';

import Header from '../layouts/Header';
import Breadcrumb from '../layouts/Breadcrumbs';
import Instafeeds from '../layouts/Instafeeds';
import Footer from '../layouts/Footertwo';
import Content from '../sections/contact/Content';

const ENV = process.env.REACT_APP_ENV;

/**
 * MAIN COMP
 * Contact Comp
 * @param {*} props
 * @returns
 */
function ContactPage(props) {
  const { t } = useTranslation();

  useEffect(() => {
    function handleScroll() {
      if (!ENV || ENV !== 'development') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    handleScroll();
  }, [props]);

  return (
    <Fragment>
      {!ENV ||
        (ENV !== 'development' && (
          <MetaTags>
            <title>Riyads Catering | Contact Us</title>
            <meta name="description" content="#" />
          </MetaTags>
        ))}

      <Header />
      <Breadcrumb breadcrumb={{ pagename: t('global:contactUs') }} />
      <Content />
      {/* <Instafeeds /> */}
      <Footer />
    </Fragment>
  );
}

export default ContactPage;
