import React, { useEffect, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../layouts/Header';
import Breadcrumb from '../layouts/BreadcrumbsModified';
import ImageSlider from '../layouts/ImageSlider';
import Footer from '../layouts/Footertwo';
import Content from '../sections/gallery/Content';

import breadCrumbImage from '../../assets/img/bg/06-1920x293.png';

const ENV = process.env.REACT_APP_ENV;

/**
 * MAIN COMP
 * Gallery Page Comp
 */
function Gallery(props) {
  useEffect(() => {
    function handleScroll() {
      if (!ENV || ENV !== 'development') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    handleScroll();
  }, [props]);

  return (
    <Fragment>
      {!ENV ||
        (ENV !== 'development' && (
          <MetaTags>
            <title>Riyads Catering | Gallery</title>
            <meta name="description" content="#" />
          </MetaTags>
        ))}
      <Header />
      <Breadcrumb pagename={'Gallery'} title={'Any'} image={breadCrumbImage} />
      <Content />
      {/* <ImageSlider /> */}
      <Footer />
    </Fragment>
  );
}

export default Gallery;
