import React, { useEffect, Fragment, useState } from 'react';
import MetaTags from 'react-meta-tags';
import { useTranslation } from 'react-i18next';
import ImageSliderMeetingEvent from 'components/layouts/ImageSliderMeetingEvent';
import Header from '../layouts/Header';
import Breadcrumb from '../layouts/BreadcrumbsModified-Banquet';
import Instafeeds from '../layouts/Instafeeds';
import Footer from '../layouts/Footertwo';
import Content from '../sections/meeting-event/Content';
import * as qs from 'query-string';
import Loader from 'components/layouts/Loader';

import MeetingConference from './meeting-event-content/MeetingConference';
import BanquetStanding from './meeting-event-content/BanquetStandingLunch';

import breadCrumbImage from '../../assets/img/bg/05-1920x293-1.jpg';

import { allOptions } from 'components/sections/meeting-event/options';

const ENV = process.env.REACT_APP_ENV;

// const allMeetingAndEvents = [{ id: 'business-meeting', name: 'Business Meeting', type: 'Meeting' }];

function MeetingEvents(props) {
  const { t } = useTranslation();

  // onLoad={() => {
  //   setTotalLoads(prevState => prevState + 1);
  // }}

  let TOTAL_IMAGES = 14;

  const [selectedOption, setSelectedOption] = useState({});

  const [totalLoads, setTotalLoads] = useState(0);
  const [isComponentLoading, setComponentLoading] = useState(true);

  useEffect(() => {
    // eslint-disable-next-line no-restricted-globals
    const meetingId = qs.parse(location.search);
    // eslint-disable-next-line no-restricted-globals
    if (meetingId) {
      if (meetingId.id) {
        const relevantOption = allOptions.find(x => x.id === meetingId.id);
        if (relevantOption) {
          setSelectedOption(relevantOption);
        }
      }
    }
  }, [props]);

  useEffect(() => {
    // console.log(totalLoads);
    if (totalLoads === TOTAL_IMAGES) {
      setComponentLoading(false);
    }
  }, [totalLoads]);

  return (
    <Fragment>
      {!ENV ||
        (ENV !== 'development' && (
          <MetaTags>
            <title>Riyads Catering | Meetings & Events</title>
            <meta name="description" content="#" />
          </MetaTags>
        ))}
      <Header />
      <Breadcrumb
        pagename={selectedOption.name || ''}
        // title={`${selectedOption.type}s` || ''}
        title={'UNIVERSAL POSTAL UNION'}
        image={breadCrumbImage}
        setTotalLoads={setTotalLoads}
      />

      {selectedOption && selectedOption.id === 'banquet-standing-lunch' && <BanquetStanding />}
      {selectedOption && selectedOption.id === 'meeting-conference' && <MeetingConference />}

      <Content selectedOption={selectedOption} setTotalLoads={setTotalLoads} />
      {/* <ImageSliderMeetingEvent setTotalLoads={setTotalLoads} /> */}
      <Footer />

      {/* {isComponentLoading && <Loader />} */}
    </Fragment>
  );
}

export default MeetingEvents;
