import React, { useState, useEffect, useRef } from 'react';
import { Link } from 'react-router-dom';
import Slider from 'react-slick';

import bgimg1 from '../../../assets/img/shop/detail-1-1-540x550.png';
import bgimg2 from '../../../assets/img/shop/detail-2-540x550.png';
import bgimg3 from '../../../assets/img/shop/detail-3-540x550.png';
import bgimg4 from '../../../assets/img/shop/detail-4-540x550.png';
import bgimg5 from '../../../assets/img/shop/detail-5-540x550.png';
import bgimg6 from '../../../assets/img/shop/detail-6-540x550.png';
import bgimg7 from '../../../assets/img/shop/detail-7-540x550.png';

import smimg1 from '../../../assets/img/shop/s-1-96x98.png';
import smimg2 from '../../../assets/img/shop/s-2-96x98.png';
import smimg3 from '../../../assets/img/shop/s-3-96x98.png';
import smimg4 from '../../../assets/img/shop/s-4-96x98.png';
import smimg5 from '../../../assets/img/shop/s-5-96x98.png';
import smimg6 from '../../../assets/img/shop/s-6-96x98.png';
import smimg7 from '../../../assets/img/shop/s-7-96x98.png';

import ContactFormModal from 'components/layouts/ContactFormModal';

// Side Content
import BusinessMeetingSideContent from './side-content/business-meeting-side';
import ConferenceSideContent from './side-content/conference-side';
import CorporateEventSideContent from './side-content/corporate-events-side';
import PrivateEventSideContent from './side-content/private-events-side';
import SeminarSideContent from './side-content/seminar';
import MeetingConferenceSideContent from './side-content/meeting-conference';

// Below Content
import Option01 from './content/option-01';
import Option02 from './content/option-02';
import Option03 from './content/option-03';
import Option04 from './content/option-04';
import Option05 from './content/option-05';
import Option06 from './content/option-06';
import Option07 from './content/option-07';

const ENV = process.env.REACT_APP_ENV;

const bigsliderpost = [
  { img: bgimg1, tag: '' },
  { img: bgimg2, tag: '' },
  { img: bgimg3, tag: '' },
  { img: bgimg4, tag: '' },
  { img: bgimg5, tag: '' },
  { img: bgimg6, tag: '' },
  { img: bgimg7, tag: '' },
  // { img: bgimg1, tag: 'Sale' },
];
const smallsliderpost = [
  { img: smimg1, id: 'heinrich-von-stephan' },
  { img: smimg2, id: 'montgomery-blair-A+B' },
  { img: smimg3, id: 'abubakr-argungu' },
  { img: smimg4, id: 'beat - fischer' },
  { img: smimg5, id: 'thurn-und-taxis' },
  { img: smimg6, id: 'henri-pequet' },
  { img: smimg7, id: 'espace-philatelique' },
];

/**
 * MAIN COMP
 * Meeting Event Comp
 * @param {*} props
 * @returns
 */

function MeetingEventInfo(props) {
  const { selectedOption = {}, setTotalLoads } = props;

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);

  const [selectedHall, setSelectedHall] = useState({ id: smallsliderpost[0].id });

  let sliderRef = useRef(null);
  let sliderRef2 = useRef(null);

  useEffect(() => {
    setNav1(sliderRef);
    setNav2(sliderRef2);
  }, [sliderRef]);

  useEffect(() => {
    function handleScroll() {
      if (!ENV || ENV !== 'development') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    handleScroll();
  }, [props]);

  const settings = {
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    arrows: false,
    dots: false,
  };
  const settings2 = {
    slidesToShow: 5,
    slidesToScroll: 1,
    fade: false,
    infinite: true,
    autoplay: false,
    arrows: false,
    dots: false,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 4,
        },
      },
    ],
  };

  return (
    <>
      <section className="Shop-section pt-120 pb-120">
        <div className="container">
          {/** SEVEN HALLS WITH SLIDER DESCRIPTION */}
          {/* <div className="row justify-content-center">
            <div className="col-lg-5">
              <div className="shop-detail-image">
                <Slider
                  className="detail-slider-1"
                  {...settings}
                  asNavFor={nav2}
                  ref={slider => {
                    sliderRef = slider;
                  }}
                >
                  {bigsliderpost.map((item, i) => (
                    <div key={i} className="slide-item">
                      <div className="image-box">
                        <Link to="#">
                          <img
                            src={item.img}
                            className="img-fluid"
                            alt="img"
                            onLoad={() => {
                              setTotalLoads(prevState => prevState + 1);
                            }}
                          />
                        </Link>
                        <span className="price">{item.tag}</span>
                      </div>
                    </div>
                  ))}
                </Slider>
                <Slider
                  className="detail-slider-2"
                  {...settings2}
                  asNavFor={nav1}
                  ref={slider => {
                    sliderRef2 = slider;
                  }}
                >
                  {smallsliderpost.map((item, i) => (
                    <div key={i} className="slide-item">
                      <div className="image-box">
                        <img src={item.img} className="img-fluid" alt="img" onClick={() => setSelectedHall(item)} />
                      </div>
                    </div>
                  ))}
                </Slider>
              </div>
            </div>
            <div className="col-lg-7">
              {selectedOption.id && selectedOption.id === 'meeting-conference' && (
                <MeetingConferenceSideContent selectedHall={{ ...selectedHall, option: selectedOption }} />
              )}
              {selectedOption.id && selectedOption.id === 'conference' && (
                <ConferenceSideContent selectedHall={{ ...selectedHall, option: selectedOption }} />
              )}
              {selectedOption.id && selectedOption.id === 'seminar' && (
                <SeminarSideContent selectedHall={{ ...selectedHall, option: selectedOption }} />
              )}
              {selectedOption.id && selectedOption.id === 'banquet-standing-lunch' && (
                <PrivateEventSideContent selectedHall={{ ...selectedHall, option: selectedOption }} />
              )}
              {selectedOption.id && selectedOption.id === 'corporate-events' && (
                <CorporateEventSideContent selectedHall={{ ...selectedHall, option: selectedOption }} />
              )}
            </div>
          </div> */}

          {/** HALL DESCRIPTION */}
          {/* {selectedOption.id && selectedHall.id === 'heinrich-von-stephan' && (
            <Option01 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'montgomery-blair-A+B' && (
            <Option02 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'abubakr-argungu' && (
            <Option03 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'beat - fischer' && (
            <Option04 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'thurn-und-taxis' && (
            <Option05 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'henri-pequet' && (
            <Option06 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )}
          {selectedOption.id && selectedHall.id === 'espace-philatelique' && (
            <Option07 selectedHall={{ ...selectedHall, option: selectedOption }} />
          )} */}
        </div>
      </section>
    </>
  );
}

export default MeetingEventInfo;
