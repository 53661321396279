import React, { useState, useEffect, useRef } from 'react';
import Slider from 'react-slick';
import { Tab, Nav } from 'react-bootstrap';
import * as qs from 'query-string';

import bgimg1 from '../../../../assets/img/shop/detail-1-540x550.png';
import bgimg2 from '../../../../assets/img/shop/detail-2.png';

import smimg1 from '../../../../assets/img/shop/s-1.png';
import smimg2 from '../../../../assets/img/shop/s-2.png';
import smimg3 from '../../../../assets/img/shop/s-3.png';
import smimg4 from '../../../../assets/img/shop/s-4.png';

import ContactFormModal from 'components/layouts/ContactFormModal';

function Option07(props) {
  const { selectedOption, setTotalLoads, selectedHall } = props;

  const [clicks, setClicks] = useState(1);
  const [showForm, setShowForm] = useState(false);

  return (
    <>
      <div className="col-12">
        <div className="product-description mt-20">
          <div className="text-center h3 mb-30">Espace philatelique</div>
          <Tab.Container defaultActiveKey="description">
            <div className="tabs">
              <Nav variant="tabs" className=" justify-content-center">
                <Nav.Item>
                  <Nav.Link eventKey="description">Description</Nav.Link>
                </Nav.Item>
                {/* <Nav.Item>
                        <Nav.Link eventKey="review">Reviews (3)</Nav.Link>
                      </Nav.Item> */}
                <Nav.Item>
                  <Nav.Link eventKey="addinfo">Additional Info</Nav.Link>
                </Nav.Item>
              </Nav>
              <Tab.Content>
                <Tab.Pane eventKey="description">
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam fringilla augue nec est tristique
                    auctor. Ipsum metus feugiat sem, quis fermentum turpis eros eget velit. Donec ac tempus ante. Fusce
                    ultricies massa massa. Fusce aliquam, purus eget sagittis vulputate, sapien libero hendrerit est,
                    sed commodo augue nisi non neque.Cras neque metus, consequat et blandit et, luctus a nunc. Etiam
                    gravida vehicula tellus, in imperdiet ligula euismod eget. Pellentesque habitant morbi tristique
                    senectus et netus et malesuada fames ac turpis egestas. Nam erat mi, rutrum at sollicitudin rhoncusp
                  </p>
                </Tab.Pane>
                {/* <Tab.Pane eventKey="review">
                        <div className="news-details-box">
                          <div className="comment-template">
                            <h3 className="box-title">03 Reviews</h3>
                            <ul className="comments-list mb-100">
                              <li>
                                <div className="comment-img">
                                  <img src={comment1} alt="img" />
                                </div>
                                <div className="comment-desc">
                                  <div className="desc-top">
                                    <h6>Rosalina Kelian</h6>
                                    <span className="date">19th May 2020</span>
                                    <Link to="#" className="reply-link">
                                      <i className="far fa-reply" />
                                      Reply
                                    </Link>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                  </p>
                                </div>
                                <ul className="children">
                                  <li>
                                    <div className="comment-img">
                                      <img src={comment2} alt="img" />
                                    </div>
                                    <div className="comment-desc">
                                      <div className="desc-top">
                                        <h6>Rosalina Kelian</h6>
                                        <span className="date">19th May 2020</span>
                                        <Link to="#" className="reply-link">
                                          <i className="far fa-reply" />
                                          Reply
                                        </Link>
                                      </div>
                                      <p>
                                        Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                        incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis
                                        nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                      </p>
                                    </div>
                                  </li>
                                </ul>
                              </li>
                              <li>
                                <div className="comment-img">
                                  <img src={comment3} alt="img" />
                                </div>
                                <div className="comment-desc">
                                  <div className="desc-top">
                                    <h6>Rosalina Kelian</h6>
                                    <span className="date">19th May 2020</span>
                                    <Link to="#" className="reply-link">
                                      <i className="far fa-reply" />
                                      Reply
                                    </Link>
                                  </div>
                                  <p>
                                    Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor
                                    incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud
                                    exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
                                  </p>
                                </div>
                              </li>
                            </ul>
                            <h3 className="box-title">Post Comment</h3>
                            <div className="comment-form">
                              <form action="#">
                                <div className="input-group input-group-two textarea mb-20">
                                  <textarea placeholder="Type your Review...." defaultValue={''} />
                                  <div className="icon">
                                    <i className="fas fa-pen" />
                                  </div>
                                </div>
                                <div className="input-group input-group-two mb-20">
                                  <input type="text" placeholder="Type your Name...." />
                                  <div className="icon">
                                    <i className="fas fa-user" />
                                  </div>
                                </div>
                                <div className="input-group input-group-two mb-20">
                                  <input type="email" placeholder="Type your email...." />
                                  <div className="icon">
                                    <i className="fas fa-envelope" />
                                  </div>
                                </div>
                                <div className="input-group  mt-30">
                                  <button type="submit" className="main-btn btn-filled">
                                    <i className="far fa-comments" /> Post Review
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                      </Tab.Pane> */}
                <Tab.Pane eventKey="addinfo" className="additional-info">
                  <div>
                    <h3 className="text-white mb-20">Additional Information</h3>
                    <table className="table">
                      {/* <thead>
                        <tr>
                          <th>Attributes</th>
                          <th>Values</th>
                        </tr>
                      </thead> */}
                      <tbody>
                        <tr>
                          <td>
                            <b>Concert</b>
                          </td>
                          <td className="value">35</td>
                        </tr>
                        <tr>
                          <td>
                            <b>U shape</b>
                          </td>
                          <td className="value">20</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Size</b>
                          </td>
                          <td className="value">40 m2</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Room height</b>
                          </td>
                          <td className="value">2.30 m</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </Tab.Pane>
              </Tab.Content>
            </div>
          </Tab.Container>
        </div>
      </div>
    </>
  );
}

export default Option07;
