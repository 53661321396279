import React, { Component } from 'react';
import {Link} from 'react-router-dom';

// import ctabg from '../../../assets/img/others/product.png';
import aboutbottomimg from '../../../assets/img/bg/03-1084x610.png';
import aboutbottomimgNew from '../../../assets/img/bg/05-1084x610.png';


class Cta extends Component {
    render() {
        return (
            <section className="pt-115 pb-115 bg-white cta-sec" style={{ backgroundImage: "url("+ aboutbottomimgNew +")" }}>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-7">
                            <div className="block-text">
                                <div className="section-title mb-20">
                                    <span className="title-tag">Visit Now</span>
                                    <h2>Hall Reservation</h2>
                                </div>
                                <p className="pr-50">
                                Efficiently designed to entertain, our halls facilitate a maximum of 300 persons. We welcome your requirements as our bravura services and organizing could assist you host a spectacular high profile event.
                                </p>
                                <Link to="#" className="main-btn btn-filled mt-40"></Link>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        );
    }
}

export default Cta;