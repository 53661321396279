import React, { useState } from 'react';
import $ from 'jquery';

import aboutimg1 from '../../../assets/img/bg/banquet-image-1.png';
import aboutimg2 from '../../../assets/img/bg/banquet-image-2.png';
import ContactFormModal from 'components/layouts/ContactFormModal';

function MeetingConference() {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  return (
    <section className="about-section style-2 pt-100 home-about-custom-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 wow fadeInLeft home-about-custom-paragraph-1 row" data-wow-delay=".3s">
            <div className="col-lg-6">
              <div className="abour-text mt-100">
                <img src={aboutimg1} className="img-fluid" alt="img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title mt-100 ">
                {/* <span className="title-tag">TOP PLACE</span> */}
                <h3>Banquet</h3>
                <p className="mt-20">
                  Our scrumptious banquet entails a gourmet experience of extensive varieties by our recognized chefs.
                  We offer cold, warm and dessert banquets. Banquet accommodation begins at 30 heads upto 300 heads.
                </p>
                <p className="mt-1">
                  Write to our Events Manager to personalize a delectable banquet as our exemplary stars service
                  prioritizes all your requirements.
                </p>
                <div className="cart-btn mt-20">
                  <div
                    className="main-btn btn-border"
                    onClick={() => {
                      setShowForm(true);
                      setSelectedItem('Banquet');
                    }}
                  >
                    Book a banquet
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 wow fadeInLeft home-about-custom-paragraph-1 row" data-wow-delay=".3s">
            <div className="col-lg-6">
              <div className="section-title mt-100 ">
                {/* <span className="title-tag">TOP PLACE</span> */}
                <h3>Standing Lunch</h3>
                <p className="mt-20">
                  A hit among our clients, standing lunches are a trend for sociable and interactive events
                  accommodating up to 450 heads.
                </p>
                <p className="mt-1">
                  Our diverse cosmopolitan cuisine adds the edge to standing lunches personalizing the event.
                </p>
                <p className="mt-1">
                  Contact our Events Manager for a customized striking standing lunch of top-grade standards.
                </p>
                <div className="cart-btn mt-20">
                  <div
                    className="main-btn btn-border"
                    onClick={() => {
                      setShowForm(true);
                      setSelectedItem('Standing Lunch');
                    }}
                  >
                    book a standing lunch
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="abour-text mt-100">
                <img src={aboutimg2} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForm && (
        <ContactFormModal
          show={showForm}
          onHide={() => setShowForm(false)}
          selectedHall={null}
          selectedItem={selectedItem}
        />
      )}
    </section>
  );
}

export default MeetingConference;
