import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import Masonry from 'react-masonry-component';
import ReactWOW from 'react-wow';

// About img
import aboutimg1 from '../../assets/img/feature/04-370x350.png';
import aboutimg2 from '../../assets/img/feature/05-370x350.png';
import aboutimg3 from '../../assets/img/feature/06-370x350.jpg';
import aboutimg4 from '../../assets/img/feature/07-370x350.jfif';
import aboutbottomimg from '../../assets/img/bg/03-1084x610.png';

class Habout extends Component {
  render() {
    const imagesLoadedOptions = {
      itemSelector: '.col-sm-6',
      percentPosition: false,
      resize: true,
      fitWidth: true,
    };
    return (
      <section className="about-section pt-115 pb-115">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <ReactWOW animation="fadeInLeft" data-delay=".3s">
              <div className="col-lg-6 col-md-10">
                <Masonry
                  className="row about-features-boxes fetaure-masonary"
                  imagesLoadedOptions={imagesLoadedOptions}
                >
                  {/* <div className="col-sm-6">
                                        <div className="single-feature-box">
                                            <div className="icon">
                                                <i class="fas fa-luggage-cart" />
                                            </div>
                                            <h4><Link to="#">Custom Suite</Link></h4>
                                            <p>
                                                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor.
                                            </p>
                                        </div>
                                    </div> */}
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg "
                      style={{ backgroundImage: 'url(' + aboutimg4 + ')' }}
                    ></div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg mt-30"
                      style={{ backgroundImage: 'url(' + aboutimg1 + ')' }}
                    ></div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg mt-30"
                      style={{ backgroundImage: 'url(' + aboutimg2 + ')' }}
                    ></div>
                  </div>
                  <div className="col-sm-6">
                    <div
                      className="single-feature-box only-bg mt-30"
                      style={{ backgroundImage: 'url(' + aboutimg3 + ')' }}
                    ></div>
                  </div>
                </Masonry>
              </div>
            </ReactWOW>
            <ReactWOW animation="fadeInRight" data-delay=".3s">
              <div className="col-lg-6 col-md-8 col-sm-10 home-what-we-do">
                <div className="abour-text pl-50 pr-50">
                  <div className="section-title mb-30">
                    <span className="title-tag">what we do</span>
                    <h3>COSMOPOLITAN CULINARY</h3>
                  </div>
                  <p>
                    Humbled but factual, we claim 31 years as connoisseurs with vast culinary expertise appetizingly
                    routed ourselves to impeccable service and masters of flavourful and diverse cuisines made by
                    recognized world class chefs.
                  </p>
                  <p className='mt-1'>Our global cuisines are our distinctive feature.</p>
                  <p className='mt-1'> Contact our Events Manager who would guide on organizing a high-class impressive event.</p>
                </div>
              </div>
            </ReactWOW>
          </div>
        </div>
        <div className="about-right-bottom">
          <div className="about-bottom-img">
            <img src={aboutbottomimg} alt="" />
          </div>
        </div>
      </section>
    );
  }
}

export default Habout;
