import React, { Component } from 'react';
import $ from 'jquery';

import aboutimg1 from '../../../assets/img/bg/about-3-500x500.png';
import aboutimg2 from '../../../assets/img/bg/about-2-500x250.png';

class About extends Component {
    // componentDidMount() {
    //     function parallaxscroll() {
    //         $(document).ready(function () {
    //             //parallax scroll
    //             $(window).on("scroll", function () {
    //                 var parallaxElement = $(".parallax_scroll_up"),
    //                     parallaxQuantity = parallaxElement.length;
    //                 window.requestAnimationFrame(function () {
    //                     for (var i = 0; i < parallaxQuantity; i++) {
    //                         var currentElement = parallaxElement.eq(i),
    //                             windowTop = $(window).scrollTop(),
    //                             elementTop = currentElement.offset().top,
    //                             elementHeight = currentElement.height(),
    //                             viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
    //                             scrolled = windowTop - elementTop + viewPortHeight;
    //                         currentElement.css({
    //                             transform: "translate3d(0," + scrolled * -0.25 + "px, 0)"
    //                         });
    //                     }
    //                 });
    //                 var parallaxElement2 = $(".parallax_scroll_down"),
    //                     parallaxQuantity2 = parallaxElement2.length;
    //                 window.requestAnimationFrame(function () {
    //                     for (var i = 0; i < parallaxQuantity2; i++) {
    //                         var currentElement2 = parallaxElement2.eq(i),
    //                             windowTop = $(window).scrollTop(),
    //                             elementTop = currentElement2.offset().top,
    //                             elementHeight = currentElement2.height(),
    //                             viewPortHeight = window.innerHeight * 0.5 - elementHeight * 0.5,
    //                             scrolled = windowTop - elementTop + viewPortHeight;
    //                         currentElement2.css({
    //                             transform: "translate3d(0," + scrolled * 0.05 + "px, 0)"
    //                         });
    //                     }
    //                 });
    //             });
    //         });
    //     }
    //     parallaxscroll();
    // }
    render() {
        return (
            <section className="about-section style-2 -pt-200 pb-20 home-about-custom-section">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 wow fadeInLeft home-about-custom-paragraph-1 " data-wow-delay=".3s">
                            <div className="abour-text mt-100">
                                <div className="section-title mb-30">
                                    <span className="title-tag">TOP PLACE</span>
                                    <h2>Meeting & Events</h2>
                                </div>
                                {/* <p className="mb-20"></p> */}
                                <img src={aboutimg2} alt="img" />
                                <p className="mt-20">Spacious and sumptuously designed our meeting rooms are created for a unique executive meeting experience. Our exemplary service crafted to equal your essentials in hosting a supreme meeting invariably ensures a lasting impression. Our excellent expertise in culinary arts and diverse event management skills is an added advantage to host your par excellence meeting.</p>
                            </div>
                        </div>
                        <div className="col-lg-6 wow fadeInRight home-about-custom-paragraph-2" data-wow-delay=".1s">
                            <img src={aboutimg1} className="img-fluid" alt="img" />
                            <p className="mt-20">Write to our Event Manager who will guide you on organizing a top-tier meeting equal to your specific requirements</p>
                        </div>

                    </div>
                </div>
            </section>

        );
    }
}

export default About;