import React, { Fragment, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import Header from '../layouts/Header';
import Footer from '../layouts/Footertwo';
import Content from '../sections/homefour/Content';

const ENV = process.env.REACT_APP_ENV;

function HomeFour() {
  useEffect(() => {
    function handleScroll() {
      if (!ENV || ENV !== 'development') {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      }
    }
    handleScroll();
  }, []);

  return (
    <Fragment>
      {!ENV ||
        (ENV !== 'development' && (
          <MetaTags>
            <title>Riyads Catering | Homepage</title>
            <meta name="description" content="#" />
          </MetaTags>
        ))}
      <Header />
      <Content />
      <Footer />
    </Fragment>
  );
}

export default HomeFour;
