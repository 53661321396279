import { hot } from 'react-hot-loader/root';
import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { HelmetProvider, Helmet } from 'react-helmet-async';

// Preloader
import Preloader from './components/layouts/Preloader';
// Pages
import Home from './components/pages/Home';
import Hometwo from './components/pages/Hometwo';
import Homethree from './components/pages/Homethree';
import Homefour from './components/pages/Homefour';
import About from './components/pages/About';
import Account from './components/pages/Account';
import Blogdetail from './components/pages/Blogdetail';
import Bloggrid from './components/pages/Bloggrid';
import Bloggridsidebar from './components/pages/Bloggridsidebar';
import Bloglist from './components/pages/Bloglist';
import Cart from './components/pages/Cart';
import Checkout from './components/pages/Checkout';
import Classification from './components/pages/Classification';
import Comingsoon from './components/pages/Comingsoon';
import Contact from './components/pages/Contact';
import Error from './components/pages/Error';
import Faq from './components/pages/Faq';
import Gallery from './components/pages/Gallery';
import Gallerytwo from './components/pages/Gallerytwo';
import Legal from './components/pages/Legal';
import Login from './components/pages/Login';
import Register from './components/pages/Register';
import Shopdetail from './components/pages/Shopdetail';
import MeetingEvents from './components/pages/MeetingEvents';
import Shopleft from './components/pages/Shopleft';
import Shoplefttwo from './components/pages/Shoplefttwo';
import Shopright from './components/pages/Shopright';
import Shoprighttwo from './components/pages/Shoprighttwo';
import Team from './components/pages/Team';
import Typography from './components/pages/Typography';
import Wishlist from './components/pages/Wishlist';

import HeaderComponent from 'components/layouts/Header';

import { I18n } from './I18n';

function App() {
  useEffect(() => {
    I18n.init();
  }, []);

  return (
    <HelmetProvider>
      <Router>
        {/* <Helmet titleTemplate="%s - Riyads Catering" defaultTitle="Riyads Catering">
          <meta name="description" content="Catering Service" />
        </Helmet> */}
        <Preloader />
        <Switch>
          <Route exact path="/" component={Homefour} />
          <Route path="/contact" component={Contact} />
          <Route path="/meeting-events" component={MeetingEvents} />
          <Route path="/about" component={About} />
          <Route path="/gallery" component={Gallery} />
          {/* <Route exact path="/" component={Home} /> */}
          {/* <Route path="/home-two" component={Hometwo} />
          <Route path="/home-three" component={Homethree} />
          <Route path="/home-four" component={Homefour} />
          <Route path="/account" component={Account} />
          <Route path="/blog-detail" component={Blogdetail} />
          <Route path="/blog-grid" component={Bloggrid} />
          <Route path="/blog-grid-sidebar" component={Bloggridsidebar} />
          <Route path="/blog-list" component={Bloglist} />
          <Route path="/cart" component={Cart} />
          <Route path="/checkout" component={Checkout} />
          <Route path="/classification" component={Classification} />
          <Route path="/coming-soon" component={Comingsoon} />
          <Route path="/faq" component={Faq} />
          <Route path="/gallery" component={Gallery} />
          <Route path="/gallery-two" component={Gallerytwo} />
          <Route path="/legal" component={Legal} />
          <Route path="/login" component={Login} />
          <Route path="/register" component={Register} />
          <Route path="/shop-detail" component={Shopdetail} />
          <Route path="/shop-left" component={Shopleft} />
          <Route path="/shop-left-two" component={Shoplefttwo} />
          <Route path="/shop-right" component={Shopright} />
          <Route path="/shop-right-two" component={Shoprighttwo} />
          <Route path="/team" component={Team} />
          <Route path="/typography" component={Typography} />
          <Route path="/wishlist" component={Wishlist} /> */}
          <Route path="/error" component={Error} />
        </Switch>
      </Router>
    </HelmetProvider>

    // <HeaderComponent />
  );
}

export default hot(App);
