import React, { useState } from 'react';
import $ from 'jquery';

import aboutimg1 from '../../../assets/img/bg/about-3-500x500.png';
import aboutimg2 from '../../../assets/img/bg/about-4-500x500.jpg';
import ContactFormModal from 'components/layouts/ContactFormModal';

function MeetingConference() {
  const [showForm, setShowForm] = useState(false);
  const [selectedItem, setSelectedItem] = useState('');

  return (
    <section className="about-section style-2 pt-100 home-about-custom-section">
      <div className="container">
        <div className="row">
          <div className="col-lg-12 wow fadeInLeft home-about-custom-paragraph-1 row" data-wow-delay=".3s">
            <div className="col-lg-6">
              <div className="abour-text mt-100">
                <img src={aboutimg2} className="img-fluid" alt="img" />
              </div>
            </div>
            <div className="col-lg-6">
              <div className="section-title mt-100 ">
                {/* <span className="title-tag">TOP PLACE</span> */}
                <h2>Meetings</h2>
                <p className="mt-20">
                  Meeting set up with top-notch catering is our tour de force. We invite you to contact our Events
                  Manager to have a detailed understanding of our services and we encourage you to communicate your
                  essentials as our services are malleable and personally tailored to ensure you host a impressionable
                  meeting. Meeting rooms can be booked at a starting capacity of 30 heads up to 300 heads.
                </p>
                <p className="mt-1">
                  Our excellent compliant services are coupled with delectable and diverse cuisine artfully presented
                  concluding a successful and sealing in a long lasting impression.
                </p>
                <div className="cart-btn mt-20">
                  <div
                    className="main-btn btn-border"
                    onClick={() => {
                      setShowForm(true);
                      setSelectedItem('Meeting');
                    }}
                  >
                    Book a Meeting
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 wow fadeInLeft home-about-custom-paragraph-1 row" data-wow-delay=".3s">
            <div className="col-lg-6">
              <div className="section-title mt-100 ">
                {/* <span className="title-tag">TOP PLACE</span> */}
                <h2>Conference</h2>
                <p className="mt-20">
                  Our proficient space and set up is ideal for various conferences; and our 31 year expertise in
                  catering with flexible tailor made services absolutely polishes your event.
                </p>
                <p className="mt-1">
                  We are accessible to your requirements and essentials and we encourage you to contact our Events
                  Manager who will assist you in personalizing your top-notch event.
                </p>
                <div className="cart-btn mt-20">
                  <div
                    className="main-btn btn-border"
                    onClick={() => {
                      setShowForm(true);
                      setSelectedItem('Conference');
                    }}
                  >
                    Book a Conference
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="abour-text mt-100">
                <img src={aboutimg1} className="img-fluid" alt="img" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {showForm && <ContactFormModal show={showForm} onHide={() => setShowForm(false)} selectedItem={selectedItem} />}
    </section>
  );
}

export default MeetingConference;
