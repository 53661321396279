import { hot } from 'react-hot-loader/root';
import React, { Component, Fragment } from 'react';
import MetaTags from 'react-meta-tags';
import Aux from 'components/hoc/_Aux';
import Header from '../layouts/Header';
import Footer from '../layouts/Footer';
import Newsletter from '../layouts/Newsletter';
import Content from '../sections/home/Content';

function Home() {
  return (
    <Aux>
      <Fragment>
        {/* <MetaTags>
          <title>Riyads Catering | Homepage</title>
          <meta name="description" content="#" />
        </MetaTags> */}
        {/* <Newsletter /> */}
        <Header />
        <Content />
        <Footer />
      </Fragment>
    </Aux>
  );
}

export default hot(Home);
