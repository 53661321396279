import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import ReactWOW from 'react-wow';
import $ from 'jquery';
import 'magnific-popup';

import img1 from '../../../assets/img/gallery/01.jpg';
import img2 from '../../../assets/img/gallery/02.jpg';
import img3 from '../../../assets/img/gallery/03.jpg';
import img4 from '../../../assets/img/gallery/04.jpg';
import img5 from '../../../assets/img/gallery/05.jpg';
import img6 from '../../../assets/img/gallery/31.jpg';
import img7 from '../../../assets/img/gallery/29.jpg';
import img8 from '../../../assets/img/gallery/08.jpg';
import img9 from '../../../assets/img/gallery/09.jpg';
import img10 from '../../../assets/img/gallery/10.jpg';
import img11 from '../../../assets/img/gallery/11.jpg';
import img12 from '../../../assets/img/gallery/12.jpg';
import img13 from '../../../assets/img/gallery/13.jpg';
import img14 from '../../../assets/img/gallery/14.jpg';
import img15 from '../../../assets/img/gallery/15.jpg';
import img16 from '../../../assets/img/gallery/16.jpg';
import img17 from '../../../assets/img/gallery/56.jpg';
import img18 from '../../../assets/img/gallery/18.jpg';
import img19 from '../../../assets/img/gallery/19.jpg';
import img20 from '../../../assets/img/gallery/20.jpg';
import img21 from '../../../assets/img/gallery/21.jpg';
import img22 from '../../../assets/img/gallery/22.jpg';
import img23 from '../../../assets/img/gallery/23.jpg';
import img24 from '../../../assets/img/gallery/24.jpg';
import img25 from '../../../assets/img/gallery/25.jpg';
import img26 from '../../../assets/img/gallery/26.jpg';
import img27 from '../../../assets/img/gallery/27.jpg';
import img28 from '../../../assets/img/gallery/28.jpg';
import img29 from '../../../assets/img/gallery/29.jpg';
import img30 from '../../../assets/img/gallery/30.jpg';
import img31 from '../../../assets/img/gallery/31.jpg';
import img32 from '../../../assets/img/gallery/32.jpg';
import img33 from '../../../assets/img/gallery/33.jpg';
import img34 from '../../../assets/img/gallery/34.jpg';
import img35 from '../../../assets/img/gallery/35.jpg';
import img36 from '../../../assets/img/gallery/36.jpg';
import img37 from '../../../assets/img/gallery/37.jpg';
import img38 from '../../../assets/img/gallery/38.jpg';
import img39 from '../../../assets/img/gallery/39.jpg';
import img48 from '../../../assets/img/gallery/48.jpg';
import img49 from '../../../assets/img/gallery/49.jpg';
import img50 from '../../../assets/img/gallery/50.jpg';
import img51 from '../../../assets/img/gallery/51.jpg';
import img40 from '../../../assets/img/gallery/40.jpg';
import img41 from '../../../assets/img/gallery/41.jpg';
import img42 from '../../../assets/img/gallery/42.jpg';
import img52 from '../../../assets/img/gallery/52.jpg';
import img53 from '../../../assets/img/gallery/53.jpg';
import img60 from '../../../assets/img/gallery/60.jpg';
import img57 from '../../../assets/img/gallery/57.jpg';
import img59 from '../../../assets/img/gallery/59.jpg';
import img58 from '../../../assets/img/gallery/58.jpg';
import img46 from '../../../assets/img/gallery/46.jpg';
import img47 from '../../../assets/img/gallery/47.jpg';
import img44 from '../../../assets/img/gallery/44.jpg';
import img45 from '../../../assets/img/gallery/45.jpg';
import img54 from '../../../assets/img/gallery/54.jpg';


const galleryposts = [
  { img: img1, animationtime: '.3s' },
  { img: img2, animationtime: '.4s' },
  { img: img15, animationtime: '.5s' },
  { img: img4, animationtime: '.6s' },
  { img: img5, animationtime: '.7s' },
  { img: img6, animationtime: '.8s' },
  { img: img7, animationtime: '.9s' },
  { img: img30, animationtime: '1.1s' },
  { img: img9, animationtime: '.9s' },
  { img: img10, animationtime: '.9s' },
  { img: img11, animationtime: '.9s' },
  { img: img12, animationtime: '.9s' },
  { img: img13, animationtime: '.9s' },
  { img: img14, animationtime: '.9s' },
  { img: img3, animationtime: '.9s' },
  { img: img16, animationtime: '.9s' },
  { img: img17, animationtime: '.9s' },
  { img: img18, animationtime: '.9s' },
  { img: img19, animationtime: '.9s' },
  { img: img20, animationtime: '.9s' },
  // { img: img21, animationtime: '.9s' },
  // { img: img22, animationtime: '.9s' },
  // { img: img23, animationtime: '.9s' },
  { img: img25, animationtime: '.9s' },
  { img: img24, animationtime: '.9s' },
  // { img: img26, animationtime: '.9s' },
  { img: img27, animationtime: '.9s' },
  { img: img28, animationtime: '.9s' },
  // { img: img29, animationtime: '.9s' },
  { img: img8, animationtime: '.9s' },
  // { img: img31, animationtime: '.9s' },
  // { img: img33, animationtime: '.9s' },
  { img: img34, animationtime: '.9s' },
  // { img: img35, animationtime: '.9s' },
  { img: img32, animationtime: '.9s' },
  { img: img36, animationtime: '.9s' },
  { img: img37, animationtime: '.9s' },
  { img: img39, animationtime: '.9s' },
  { img: img48, animationtime: '.9s' },
  { img: img49, animationtime: '.9s' },
  { img: img38, animationtime: '.9s' },
  { img: img50, animationtime: '.9s' },
  { img: img40, animationtime: '.9s' },
  { img: img51, animationtime: '.9s' },
  // { img: img41, animationtime: '.9s' },
  // { img: img42, animationtime: '.9s' },
  { img: img52, animationtime: '.9s' },
  { img: img60, animationtime: '.9s' },
  { img: img53, animationtime: '.9s' },
  { img: img57, animationtime: '.9s' },
  { img: img58, animationtime: '.9s' },
  { img: img46, animationtime: '.9s' },
  { img: img47, animationtime: '.9s' },
  { img: img44, animationtime: '.9s' },
  { img: img45, animationtime: '.9s' },
  { img: img54, animationtime: '.9s' },
  { img: img48, animationtime: '.9s' },
  // { img: img59, animationtime: '.9s' },
];
class Content extends Component {
  componentDidMount() {
    function popup() {
      $('.gallery-loop .popup-image').magnificPopup({
        type: 'image',
        gallery: {
          enabled: true,
        },
        mainClass: 'mfp-fade',
      });
    }
    popup();
  }
  render() {
    return (
      <div className="gallery-wrappper pt-120 pb-120">
        <div className="container">
          <div className="gallery-loop">
            {galleryposts.map((item, i) => (
              <ReactWOW key={i} animation="fadeInUp" data-wow-delay={item.animationtime}>
                <div className="single-gallery-image">
                  <Link to={item.img} className="popup-image">
                    <img src={item.img} alt="" />
                  </Link>
                </div>
              </ReactWOW>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Content;
