import React, { useState } from 'react';
import { Alert, Modal, Spinner } from 'react-bootstrap';
import axios from 'axios';
// import { useSnackbar } from 'notistack';

// #region assets
import popupimg from '../../assets/img/popup.jpg';
import logo from '../../assets/img/logo-white-riyad.png';
import { BASE_URL } from 'constants/api';

function ContactFormModal(props) {
  const { show = false, onHide, selectedHall = { option: {} }, selectedItem } = props;

  // const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const [formData, setFormData] = useState({});

  const [loading, setLoading] = useState(false);
  const [alert, setAlert] = useState({ message: null, variant: 'success' });

  const handleInput = e => {
    const { id, value } = e.target;
    setFormData({ ...formData, [id]: value });
  };

  const handleSubmit = async e => {
    e.preventDefault();

    setLoading(true);

    const { email, fullName, subject, message } = formData;

    if (
      !email ||
      !fullName ||
      !subject ||
      !message ||
      email === '' ||
      fullName === '' ||
      subject === '' ||
      message === ''
    ) {
      setAlert({ ...alert, message: 'Fill all fields', variant: 'danger' });
      setLoading(false);
      return;
    }

    setAlert({ ...alert, message: null });

    try {
      const response = await fetch(`${BASE_URL}test/rc-email-item-inquiry`, {
        method: 'post',
        headers: {
          Accept: 'application/json, text/plain, */*',
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, item: selectedItem }),
      });
      if (response && response.status === 200) {
        setAlert({ ...alert, message: 'Successfully submitted request', variant: 'success' });
        setFormData({
          email: '',
          fullName: '',
          subject: '',
          message: '',
          phoneNumber: '',
        });
        try {
          e.target.reset();
        } catch (e) {}

        setTimeout(() => {
          setAlert({ ...alert, message: null });
        }, 1500);
      }
    } catch (e) {
      console.log(e);
      setAlert({ ...alert, message: 'Unable to send email', variant: 'danger' });
    }

    setLoading(false);
    setTimeout(() => {
      onHide();
    }, 1500);
  };

  const handleChange = e => {
    const { id, value } = e.target;
    setFormData({
      ...formData,
      [id]: value,
    });
  };

  return (
    <Modal
      show={show}
      className="on-load-modal"
      onHide={() => {
        if (onHide) {
          onHide();
        }
      }}
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      {/* <div className="modal-content" style={{ backgroundImage: 'url(' + popupimg + ')' }}> */}
      <div className="modal-content" style={{ backgroundColor: '#454540' }}>
        <Modal.Header>
          <button
            type="button"
            className="close"
            onClick={() => {
              if (onHide) {
                onHide();
              }
            }}
          >
            <span aria-hidden="true">×</span>
          </button>
        </Modal.Header>
        <Modal.Body>
          <div>
            {/* <h3 className="title">Newsletters</h3> */}
            <h3 className="title">
              <img src={logo} alt="img" width={155} height={70} />
            </h3>
            {/* <h4
              className="title"
              style={{ fontStyle: 'italic', fontWeight: 'lighter', marginTop: 20, marginBottom: 10 }}
            >
              Contact us
            </h4> */}
            <br />
            {/* <p>Subscribe to our newsletter to recieve exclusive offers</p> */}
            <div className="contact-form">
              <form action="#" onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon">
                        <i className="far fa-user" />
                      </span>
                      <input
                        type="text"
                        placeholder="Your full name"
                        onChange={handleChange}
                        id="fullName"
                        value={formData.fullName}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon">
                        <i className="far fa-envelope" />
                      </span>
                      <input
                        type="email"
                        placeholder="Enter email address"
                        onChange={handleChange}
                        id="email"
                        value={formData.email}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon">
                        <i className="far fa-phone" />
                      </span>
                      <input
                        type="text"
                        placeholder="Add phone number"
                        onChange={handleChange}
                        id="phoneNumber"
                        value={formData.phoneNumber}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input-group mb-30">
                      <span className="icon">
                        <i className="far fa-book" />
                      </span>
                      <input
                        type="text"
                        placeholder="Select Subject"
                        onChange={handleChange}
                        id="subject"
                        value={formData.subject}
                      />
                    </div>
                  </div>
                  {/* <p className="mb-10 ml-20 ">Hall Reservation</p>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                      <span className="icon">
                        <i className="far fa-pen" />
                      </span>
                      <textarea
                        placeholder="Enter messages"
                        defaultValue={''}
                        style={{ height: 150 }}
                        onChange={handleChange}
                        id="hallReservationMessage"
                      />
                    </div>
                  </div> */}
                  <p className="mb-10 ml-20 ">Message</p>
                  <div className="col-12">
                    <div className="input-group textarea mb-30">
                      <span className="icon">
                        <i className="far fa-pen" />
                      </span>
                      <textarea
                        placeholder="Enter messages"
                        defaultValue={''}
                        style={{ height: 150 }}
                        onChange={handleChange}
                        id="message"
                        value={formData.message}
                      />
                    </div>
                  </div>
                  {alert.message && (
                    <Alert variant={alert.variant} className="w-100">
                      {alert.message}
                    </Alert>
                  )}
                  <div className="col-12 text-center">
                    <button type="submit" className="main-btn-custom btn-filled-custom" style={{ minHeight: 50 }}>
                      {loading ? (
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignContent: 'center',
                            alignItems: 'center',
                            paddingTop: 10,
                            paddingBottom: 10,
                          }}
                        >
                          <Spinner animation="border" className="mt-1" size="sm" />
                        </div>
                      ) : (
                        <> SEND INQUIRY</>
                      )}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </Modal.Body>
      </div>
    </Modal>
  );
}

export default ContactFormModal;
