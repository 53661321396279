import React, { Component } from 'react';

// import logo from 'assets/img/logo-only.png';
import logo from 'assets/img/logo-white-riyad.png';

function Loader() {
  return (
    <div className="loader custom-loader" >
      <img src={logo} alt="img" width={300} height={150} className="mb-2 mr-n2 animate-flicker" />
    </div>
  );
}

export default Loader;
